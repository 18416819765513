import React from 'react';
import Emplist from './emplist';
import { Box } from '@chakra-ui/react';

const DutyRoster = () => {
	return (
		<Box>
			<Emplist />
		</Box>
	);
};

export default DutyRoster;
